<template>
  <div :class="['header-wrap', { sticky: isSticky }]">
    <!-- Top Header -->
    <div class="header-top" :class="{ open: open }">
      <button type="button" class="close-sidebar" @click="open = !open">
        <i class="ri-close-fill"></i>
      </button>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-9">
            <div class="header-top-left">
              <ul class="contact-info list-style">
                <li>
                  <i class="flaticon-call"></i>
                  <a href="tel:02459271449">(+229) 21 31 79 58</a>
                </li>
                <li>
                  <i class="flaticon-email-1"></i>
                  <a href="mailto:contact@laposte.bj">contact@laposte.bj</a>
                </li>
                <li>
                  <i class="flaticon-pin"></i>
                  <p>Route du port, Cotonou, Bénin</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-3">
            <div class="header-top-right">
              <ul class="header-top-menu list-style">
                <li>
                  <a href="https://shake.o2switch.net:2096" target="_blank"
                    >Webmail</a
                  >
                </li>
                <li><router-link to="/faq">FAQ</router-link></li>
                <li class="nav-item">
                  <a
                    a
                    href="http://41.85.184.12/webtracking"
                    class="nav-link"
                    target="_blank"
                    >Suivi de colis</a
                  >
                </li>
                <li class="nav-item">
                  <a a href="javascript:void(0);" class="nav-link">
                    {{ totalVisiteurs }} Visiteurs</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Top Header -->

    <!-- Navbar -->
    <div class="header-bottom">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <router-link class="navbar-brand" to="/">
            <img
              class="logo-light"
              src="../../assets/images/logo.png"
              style="height: 70px !important"
              alt="logo"
            />
            <img
              class="logo-dark"
              src="../../assets/images/logo-white.png"
              alt="logo"
              style="height: 70px !important"
            />
          </router-link>
          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <router-link to="/" class="nav-link">Accueil</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/about" class="nav-link">A Propos</router-link>
              </li>
              <li class="nav-item has-dropdown">
                <a href="#" class="nav-link">
                  Nos Services
                  <i class="ri-arrow-down-s-line"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a href="#" class="nav-link">
                      Services Postaux
                      <i class="ri-arrow-down-s-line"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/colisPostaux" class="nav-link"
                          >Colis postaux</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/envoisExpress" class="nav-link"
                          >Envois Express</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/philatelie" class="nav-link"
                          >Philatélie</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/posteLettre" class="nav-link"
                          >Poste aux lettres</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/serviceDistribution" class="nav-link"
                          >Service distribution</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a href="#" class="nav-link">
                      Services Financiers
                      <i class="ri-arrow-down-s-line"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/assurances" class="nav-link"
                          >Assurances</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/centreCheque" class="nav-link"
                          >Produits du Centre de Chèques Postaux
                          (CCP)</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/caisseNational" class="nav-link"
                          >Produit de la Caisse Nationale d'Epargne</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/transfertArgent" class="nav-link"
                          >Transferts d'argent et finance digitale</router-link
                        >
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item">
                    <a href="#" class="nav-link">
                      Services connexes
                      <i class="ri-arrow-down-s-line"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/posteVerte" class="nav-link"
                          >Initiative Poste Verte</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/posteVoyage" class="nav-link"
                          >Poste Voyages</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/produitPartenariat" class="nav-link"
                          >Produits de partenariat</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <router-link to="produitColisPostaux" class="nav-link"
                      >Tarifiacation</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item has-dropdown">
                <a href="#" class="nav-link">
                  e-Services
                  <i class="ri-arrow-down-s-line"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/servicePoste" class="nav-link"
                      >e-Services de la Poste</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/servicePublic" class="nav-link"
                      >e-Services publics</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/faqeservice" class="nav-link"
                      >FAQ e-Services</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link to="/actualite" class="nav-link"
                  >Actualités</router-link
                >
              </li>
              <li class="nav-item">
                <a a href="http://boutique.nostests.com" class="nav-link" target="_blank" >Boutique</a>
              </li>
              <li class="nav-item has-dropdown">
                <a href="#" class="nav-link">
                  Médiathèques
                  <i class="ri-arrow-down-s-line"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/mediatheque" class="nav-link"
                      >Galerie Photos</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/mediathequeVideo" class="nav-link"
                      >Galerie Vidéos</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/document" class="nav-link"
                      >Document</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link to="/contact" class="nav-link"
                  >Contact</router-link
                >
              </li>
            </ul>

            <div class="others-options d-flex align-items-cente">
              <div class="header-btn">
                <a
                  href="https://bp.laposte.bj/"
                  class="btn btn-sm style1"
                  style="font-size: 16px !important;"
                  target="_blank">
                    <b>E-boîte postale</b>
                  </a>
              </div>
            </div>
          </div>

          <div class="mobile-bar-wrap">
            <div
              class="mobile-sidebar"
              @click="open = !open"
              :aria-pressed="open ? 'true' : 'false'"
              v-bind:class="{ open: button_open_state }"
              v-on:click="button_open_state = !button_open_state"
            >
              <i class="ri-menu-4-line"></i>
            </div>
            <button
              class="searchbtn"
              type="button"
              @click="search = !search"
              :aria-pressed="search ? 'true' : 'false'"
              v-bind:class="{ search: button_search_state }"
              v-on:click="button_search_state = !button_search_state"
            >
              <i class="flaticon-search"></i>
            </button>
            <div
              class="navbar-toggler mobile-menu xl-none"
              @click="active = !active"
              :aria-pressed="active ? 'true' : 'false'"
              v-bind:class="{ active: button_active_state }"
              v-on:click="button_active_state = !button_active_state"
            >
              <a href="javascript:void(0);">
                <i class="ri-menu-line"></i>
                <i class="ri-close-line"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>

      <!-- Search Modal -->
      <div class="search-area" :class="{ search: search }">
        <div class="container">
          <form @submit.prevent>
            <div class="form-group">
              <input type="search" placeholder="Search Here" autofocus />
            </div>
          </form>
          <button
            type="button"
            class="close-searchbox"
            @click="search = !search"
          >
            <i class="ri-close-line"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ApiService from "@/services/ApiService";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "NavBar",
  setup() {
    const isSticky = ref(false);
    const active = ref(false);
    const button_active_state = ref(false);
    const search = ref(false);
    const button_search_state = ref(false);
    const open = ref(false);
    const button_open_state = ref(false);
    const totalVisiteurs = ref(0);

    onMounted(() => {
      window.addEventListener("scroll", () => {
        let scrollPos = window.scrollY;
        if (scrollPos >= 100) {
          isSticky.value = true;
        } else {
          isSticky.value = false;
        }
      });
      getNbVisiteurs();
    });

    const getNbVisiteurs = () => {
      return ApiService.get("/visiteurs")
        .then(({ data }) => {
          console.log("TRTRTRRRTRTRTYUIYTYIUO ===> ", data);
          totalVisiteurs.value = data.data.data;
        })
        .catch(({ response }) => {
          //error(response.data.message)
        });
    };

    return {
      isSticky,
      active,
      button_active_state,
      search,
      button_search_state,
      open,
      button_open_state,
      totalVisiteurs,
    };
  },
});
</script>
