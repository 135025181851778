<template>
    <div class="service-wrap pt-20 pb-20">
        <div class="container">

            <div class="row ">
                <div class="col-xl-4 col-lg-6 col-md-6" v-for="(article, index) in truncatedArticles" :key="index">
                    <div class="blog-card style1">
                        <div class="blog-img"  :style="{height:'277px', backgroundImage: `url(${getUrlApiForFiles(article.urlImage)})`, backgroundSize: 'cover',backgroundPosition: 'center'}"
                        >
                        </div>
                        <div class="blog-info" style="min-height: 250px;">
                            <ul class="blog-metainfo  list-style">
                                <li><i class="flaticon-user"></i> <router-link to="#">La Poste du Bénin SA</router-link></li>
                                <li><i class="flaticon-calendar"></i>{{ format_Date(article.datePublication)  }}</li>
                            </ul>
                            <h3><router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }">{{ article.titre }}</router-link></h3>
                            <router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }" class="link style1">Lire plus
                                <i class="flaticon-right-arrow"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="">
                <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error,format_Date } from "@/utils/utils";
import { defineComponent, ref, onMounted, computed } from "vue";
import { Article } from "@/models/Article";
import PaginationComponent from '@/components/Utilities/Pagination.vue';

export default defineComponent({
    name: 'ActualitePoste',
    components: {
      PaginationComponent,

  },
  setup(){

    const articles = ref<Array<Article>>([]);   

        // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getTreeArticle(page_, limit_);
      } catch (error) {
        //
      }
    };

    function rechercher(){
        getTreeArticle(page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE

    function getTreeArticle(page = 1, limi = 9, searchTerm = '') {
      return ApiService.get(`/actualite/articles/22?page=${page}&limit=${limi}&mot=${searchTerm}&`)
      .then(({ data }) => {
        articles.value = data.data.data;
        totalPages.value = data.data.totalPages;
        limit.value = data.data.limit;
        totalElements.value = data.data.totalElements;
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    } 

    const truncatedArticles = computed(() => {
        const maxLength = 100; 
        return articles.value.map(article => {
            if (article.titre.length > maxLength) {
            return {
                ...article,
                titre: article.titre.substring(0, maxLength) + '...'
            };
            }
            return article;
        });
    });

    // function getTreeArticle() {
    //   return ApiService.get(`/actualite/articles/22`)
    //     .then(({ data }) => {
    //       articles.value = data.data.data;
    //       console.log(articles.value);
    //       return data.data;
    //     })
    //     .catch(({ response }) => {
    //       error(response.message)
    //   });
    // }
    
    onMounted(() => {
        getTreeArticle();
    });

    return { 
        articles,
        getUrlApiForFiles,
        format_Date,
        page, 
        totalPages,
        limit,
        totalElements,
        handlePaginate,
        truncatedArticles
    };
  },
});
</script>

<style scoped>
    .blog-card .blog-info h3 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 15px;
    }
</style>