<template>
    <div class="service-wrap ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6" v-for="(article, index) in articles" :key="index">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-payment-method"></i></span>
                                <h3><router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }">{{ article.titre }}</router-link></h3>
                            </div>
                            <router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }" class="link style1">Lire plus
                                <i class="flaticon-right-arrow"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

            </div>
            <div class="text-center"><a href="https://tawk.to/lapostebenineservice" target="_blank" class="btn btn-sm style1">Discuter avec un agent de La Poste du Bénin SA</a></div>
        </div>
    </div>
</template>



<script lang="ts">

import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error,format_date } from "@/utils/utils";
import { defineComponent, ref, onMounted } from "vue";
import { Article } from "@/models/Article";

export default defineComponent({
    name: 'ServicePoste',
    components: {
  },
  setup(){

    const articles = ref<Array<Article>>([]);   

    function getTreeArticle() {
      return ApiService.get(`/actualite/articles/20`)
        .then(({ data }) => {
          articles.value = data.data.data;
          console.log(articles.value);
          return data.data;
        })
        .catch(({ response }) => {
          error(response.message)
      });
    }
    
    onMounted(() => {
        getTreeArticle();
    });

    return { 
        articles,
        getUrlApiForFiles,
        format_date
    };
  },
});
</script>