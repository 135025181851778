<template>
    <nav class="">
        <ul class="page-nav list-style mt-20">
        <li :class="{disabled:page === 1 }">
            <a  href="javascript:;" aria-label="Previous"
            @click="pagination(page-1, limit)">
            <i class="flaticon-back"></i>
            </a>
        </li>
        <li v-for="n in pageNumbers" :key="n" @click="pagination(n, limit)" class="page-item">
            <a :class="{ active: n === page }"  href="javascript:;">{{ n }}</a>
        </li>
        <li :class="{disabled:page === totalPages }">
            <a href="javascript:;" aria-label="Next"
            @click="pagination(page+1, limit)">
            <i class="flaticon-next-1"></i>
            </a>
        </li>
        </ul>
    </nav>
</template>

<script>
import { ref, computed } from 'vue';

export default {
    name: 'PaginationComponent',
    emits: ['paginate'],
  props: ['page', 'totalPages', 'limit','totalElements'],
  setup(props, { emit }) {
    const pageNumbers = computed(() => {
      let numbers = [];
      let start = props.page - 2 > 0 ? props.page - 2 : 1;
      let end = props.page + 2 <= props.totalPages ? props.page + 2 : props.totalPages;

      if (start > 1) {
        numbers.push('<<', 1, '...');
      }

      for (let i = start; i <= end; i++) {
        numbers.push(i);
      }

      if (end < props.totalPages) {
        numbers.push('...', props.totalPages, '>>');
      }

      return numbers;
    });

    const pagination = (page_, limit_) => {
      // emit('paginate', { page_, limit_ });
      // if (typeof page_ === 'number') {
      //   emit('paginate', { page_, limit_ });
      // }
      if (typeof page_ === 'string') {
        switch (page_) {
          case '<<':
            emit('paginate', { page_: 1, limit_ });
            break;
          case '>>':
            emit('paginate', { page_: props.totalPages, limit_ });
            break;
        }
      } else if (typeof page_ === 'number') {
        emit('paginate', { page_, limit_ });
      }

    };

    return { pageNumbers, pagination };
  },
};
</script>