<template>
  <footer class="footer-wrap bg-rock">
    <div class="container">
      <img
        src="../../assets/images/footer-shape-1.png"
        alt="Image"
        class="footer-shape-one"
      />

      <img
        src="../../assets/images/footer-shape-2.png"
        alt="Image"
        class="footer-shape-two"
      />
      <div class="row pt-100 pb-75">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="footer-widget">
            <router-link to="/" class="footer-logo">
              <img
                src="../../assets/images/lapostebjw.png"
                alt="Image"
                style="height: 100px"
              />
            </router-link>
            <p class="comp-desc">
              Notre mission est de " Satisfaire une clientèle diversifiée et
              exigeante par la fourniture des services du courrier universel,
              des services financiers, des prestations de e-commerce..." <br />
              <br />
              Suivez nous sur nos réseaux sociaux :
            </p>
            <div class="social-link">
              <ul class="social-profile list-style style1">
                <li>
                  <a target="_blank" href="https://www.facebook.com/laposte.bj">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com/PosteduBeninSA">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://bj.linkedin.com/company/la-poste-du-b%C3%A9nin"
                  >
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/postebenin"
                  >
                    <i class="ri-instagram-fill"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Notre entreprise</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/actualite">
                  <i class="flaticon-next"></i>
                  Actualités
                </router-link>
              </li>
              <li>
                <router-link to="/about">
                  <i class="flaticon-next"></i>
                  A propos
                </router-link>
              </li>
              <li>
                <router-link to="/services">
                  <i class="flaticon-next"></i>
                  Nos services
                </router-link>
              </li>
              <li>
                <router-link to="/contact">
                  <i class="flaticon-next"></i>
                  Nous contactez
                </router-link>
              </li>
              <li>
                <a href="https://shake.o2switch.net:2096" target="_blank"
                  ><i class="flaticon-next"></i> Webmail</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Liens utiles</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/faq">
                  <i class="flaticon-next"></i>
                  FAQ
                </router-link>
              </li>
              <li>
                <router-link to="/contact">
                  <i class="flaticon-next"></i>
                  Contact
                </router-link>
              </li>
              <li>
                <a href="https://bp.laposte.bj" target="_blank">
                  <i class="flaticon-next"></i>
                  E-boite postale
                </a>
              </li>
              <li>
                <router-link to="/simulation">
                  <i class="flaticon-next"></i>
                  Simulation
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Newsletter</h3>
            <p class="newsletter-text">
              Inscrivez-vous à notre newsletter pour recevoir les dernières
              nouvelles et actualités.
            </p>
            <form @submit.prevent="handleSubmit" class="newsletter-form">
              <input
                type="email"
                v-model="email"
                placeholder="Votre email"
                :class="{ 'is-invalid': emailError }"
              />
              <span v-if="emailError" class="error-message">{{
                emailError
              }}</span>
              <button type="submit"
              style="font-size: 17px !important;font-weight: bold !important;">
                S'inscrire</button>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            style="
              color: white !important;
              text-align: center;
              margin-bottom: 15px !important;
            "
          >
            <router-link
              to="/mentionLegale"
              style="text-decoration: none; color: white; margin: 0px 10px"
              >Mentions légales
            </router-link>
            <router-link
              to="/donnePersonnelle"
              style="text-decoration: none; color: white; margin: 0px 10px"
              >Données personnelles</router-link
            >
            <router-link
              to="/cookies"
              style="text-decoration: none; color: white; margin: 0px 10px"
              >Cookies</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-text">
      <p>
        <i class="ri-copyright-line"></i>{{ currentYear }} LA POSTE DU BENIN SA.
        Tout droit réservé.
      </p>
    </div>
  </footer>
</template>

<script lang="ts">
import { eventBus } from "@/utils/redirection";
import { error, success } from "@/utils/utils";
import axios from "axios";

export default {
  name: "FooterComp",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      email: "", // Email du formulaire
      emailError: null as string | null, // Erreur de validation
    };
  },
  methods: {
    validateEmail(email: string): boolean {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expression régulière pour l'email
      return emailRegex.test(email);
    },

    // Méthode pour gérer la soumission du formulaire
    async handleSubmit() {
      if (this.validateEmail(this.email)) {
        try {
          const response = await axios.post("/newsletters", {
            email: this.email,
          });
          // Vérifier le code de statut de la réponse
          if (response.status === 201) {
            this.email = ""; // Réinitialiser l'email après la soumission
            this.emailError = null; // Réinitialiser l'erreur
            success(response.data.message); // Afficher un message de succès
          }
        } catch (err) {
          if (err.code && err.code == "ERR_NETWORK") {
            error("Vérifiez votre connexion au serveur");
          } else {
            error(err.response.data.message);
          }
        }
      } else {
        this.emailError = "Veuillez entrer une adresse email valide.";
      }
    },

    showSimulation() {
      eventBus.value.showSimulation = !eventBus.value.showSimulation;
    },
  },
};
</script>
<style>
.newsletter-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  margin: 0 auto;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.is-invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.85em;
}
</style>
