<template>
    <div class="sidebar">
        <div class="sidebar-widget style4">
            <div ><a href="https://tawk.to/lapostebenineservice" target="_blank" class="btn btn-sm style1">Discuter avec un agent</a></div>
        </div>
        
        <div class="sidebar-widget categories">
            <h4>Nouvelles catégories</h4>
            <div class="category-box">
                <ul class="list-style">
                    <li v-for="(categorie, index) in categorieArticles" :key="index">
                        <router-link to="#">
                            <i class="ri-login-box-line"></i>
                            {{ categorie.nom }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="sidebar-widget popular-post">
            <h4>Autres articles</h4>
            <div class="popular-post-widget">
                <div class="pp-post-item" v-for="(article, index) in articles" :key="index">
                    <router-link to="#" class="pp-post-img" v-if="article?.urlImage">
                        <img :src="getUrlApiForFiles(article.urlImage)" alt="Image">
                    </router-link>
                    <div class="pp-post-info">
                        <span><i class="flaticon-calendar"></i>{{ format_Date(article.datePublication) }}</span>
                        <h6>
                            <router-link to="blog-details">
                                {{ article.titre }}
                            </router-link>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="sidebar-widget tags">
            <h4>Tags populaires </h4>
            <div class="tag-list">
                <ul class="list-style">
                    <li v-for="(tag, index) in tags" :key="index">
                        <router-link to="">{{ tag.nom }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { defineComponent, onMounted, ref, watch,inject,watchEffect } from "vue";
import ApiService from "../../services/ApiService";
import { format_Date, suppression, error, getUrlApiForFiles} from "../../utils/utils";
import {Tag} from '../../models/Tag';
import { CategorieArticle } from '@/models/CategorieArticle';
import { Article } from '@/models/Article';

export default {
    name: 'ViewArticleSidebar',
    // props: {
    //     categorie: Number
    // },
    // emits: ["categorie"],
    setup: () => {

    const tags = ref<Array<Tag>>([]);
    const tag = ref<Tag>();
    const categorieArticles = ref<Array<CategorieArticle>>([]);
    const categorieArticle = ref<CategorieArticle>();
    const articles = ref<Array<Article>>([]);   
    const article = ref<Article>();
    
    const categorie = inject('categorie', ref(0));

    function getAllTags() {
      return ApiService.get('tags')
      .then(({ data }) => {
        tags.value = data.data.data.slice(-4);
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    } 

    function getAllCategorieArticles() {
      return ApiService.get('categorieArticles')
      .then(({ data }) => {
        categorieArticles.value = data.data.data.slice(-4);
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    } 

    function getTreeArticle(categorieValue) {
      return ApiService.get(`/actualite/articles/${categorieValue}`)
        .then(({ data }) => {
          articles.value = data.data.data.slice(-3);
          console.log(articles.value);
         console.log('categorieValue', categorieValue);
          return data.data;
        })
        .catch(({ response }) => {
          //error(response.message)
      });
    }
    

    // function getAllArticle() {
    //   return ApiService.get('/articles')
    //     .then(({ data }) => {
    //       articles.value = data.data.data.slice(-3);
    //       return data.data.data;
    //     })
    //     .catch(({ response }) => {
    //       error(response.data.message)
    //   });
    // }


    onMounted(() => {
      getAllTags();
      getAllCategorieArticles();
    //   getAllArticle()
        getTreeArticle(categorie.value);
        console.log('Categorie view article', categorie.value)
    });

    
watchEffect(() => {
  getTreeArticle(categorie.value);
});

    return { tag,
            tags,
            getAllTags,
            categorieArticle,
            categorieArticles,
            getAllCategorieArticles,
            article,
            articles,
            // categorieT,
            //getAllArticle,
            format_Date,
            suppression,
            getUrlApiForFiles,

    };
  },
}
</script>