<template>
    <div class="pricing-table-wrap pt-20 pb-20">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12" >
                    <div class="pricing-table style1">
                        <div class="pricing-header">
                            <h3><img src="../../assets/images/pricing-star.png" alt="Image">Règlementation UPU et Divers Guides</h3>
                        </div>
                    </div>
                </div>

                <div>
                    <a
                    class="btn btn-primary" style="font-size: 19px;padding: 8px 10px;margin: 5px 5px;"
                    v-for="(article, index) in articles" :key="index"
                    target="_blank" rel="noopener noreferrer"
                    :href="article.source ? article.source : getUrlApiForFiles(article.urlImage)">
                        {{ article.titre }}
                        <i class="ri-download-fill"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error,format_date } from "@/utils/utils";
import { defineComponent, ref, onMounted } from "vue";
import { Article } from "@/models/Article";

export default defineComponent({
    name: 'DocumentPoste',
    components: {
  },
  setup(){

    const articles = ref<Array<Article>>([]);   

    function getTreeArticle() {
      return ApiService.get(`/actualite/articles/65`)
        .then(({ data }) => {
          articles.value = data.data.data;
          console.log(articles.value);
          return data.data;
        })
        .catch(({ response }) => {
          error(response.message)
      });
    }
    
    onMounted(() => {
        getTreeArticle();
    });

    return { 
        articles,
        getUrlApiForFiles,
        format_date,
    };
  },
});
</script>

<style>

</style>