

<template>
    <div class="sidebar">
        <div class="sidebar-widget categories">
            <h4>Nouvelles catégories</h4>
            <div class="category-box">
                <ul class="list-style">
                    <li>
                        <router-link to="produitColisPostaux">
                            <i class="ri-login-box-line"></i>
                            Tarifs Colis postaux
                        </router-link>
                    </li>
                    <li>
                        <router-link to="produitEnvoisExpress">
                            <i class="ri-login-box-line"></i>
                            Tarifs Envois express
                        </router-link>
                    </li>
                    <li>
                        <router-link to="produitPosteLettre">
                            <i class="ri-login-box-line"></i>
                            Tarifs Poste aux lettres
                        </router-link>
                    </li>
                    <li>
                        <router-link to="produitTransfert">
                            <i class="ri-login-box-line"></i>
                            Tarifs de nos moyens de transferts
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
    name: 'MenuProduit',
    components: {
  },
})

</script>