<template>
  <div class="service-wrap ptb-100">
    <div class="container">
      <div class="row justify-content-center">
        <div
          class="col-xl-4 col-lg-6 col-md-6"
          v-for="(article, index) in articles"
          :key="index"
        >
          <div class="service-card style4">
            <div class="service-info">
              <div class="service-title">
                <span v-if="article.icone"
                  ><img
                    :src="getUrlApiForFiles(article.icone)"
                    alt="Image"
                    style="width: 100px; height: auto; object-fit: contain"
                /></span>
                <span v-if="!article.icone"
                  ><i class="flaticon-payment-method"></i
                ></span>
                <h3>
                  <a :href="article.source" target="_blank">{{
                    article.titre
                  }}</a>
                  <!-- <router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }">{{ article.titre }}</router-link> -->
                </h3>
              </div>
              <!-- <router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }" class="link style1">Lire plus
                                <i class="flaticon-right-arrow"></i>
                            </router-link> -->
              <a :href="article.source" target="_blank" class="link style1"
                >Obtenir la pièce <i class="flaticon-right-arrow"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a
          href="https://wa.me/+22946621196/?text=Bonjour, j'ai besoin d'une pièce"
          target="_blank"
          class="btn btn-sm style1"
          >Discuter avec un agent de La Poste du Bénin SA</a
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error, format_date } from "@/utils/utils";
import { defineComponent, ref, onMounted } from "vue";
import { Article } from "@/models/Article";

export default defineComponent({
  name: "servicePublic",
  components: {},
  setup() {
    const articles = ref<Array<Article>>([]);
    function getTreeArticle() {
      return ApiService.get(`/actualite/articles/20`)
        .then(({ data }) => {
          articles.value = data.data.data;
          // trier les article dans l'order du champ numero
          //convcertir numero en entier
          articles.value.sort((a, b) => Number(a.numero) - Number(b.numero));
          console.log("mes données", articles.value);
          return data.data;
        })
        .catch(({ response }) => {
          error(response.message);
        });
    }

    onMounted(() => {
      getTreeArticle();
    });

    return {
      articles,
      getUrlApiForFiles,
      format_date,
    };
  },
});
</script>
