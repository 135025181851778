<template>
    <div class="about-wrap style1 pt-20 pb-20">
        <div class="container">
            <div v-for="apropos in Aproposs" :key="apropos.id">
                <div class="row gx-5">
                    <div class="col-lg-6">
                        <div  ref="blockvaleur"  class="about-content section"  :class="{ visible: valeur }">
                            <div class="content-title style1">
                                <h3>Nos valeurs</h3>
                                <p class="" v-html="apropos.valeur"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-20"><img src="../../assets/images/about/Poste-AuxLettres.png" /></div>
                    </div>
                </div>
                <div class="row gx-5">
                    <div class="col-lg-6">
                        <div class="mb-20"> <img src="../../assets/images/about/Colis-Postaux.png" /></div>
                    </div>
                    <div class="col-lg-6">
                        <div ref="blockmission" class="about-content section mb-20" :class="{ visible: mission }" >
                            <div class="content-title style1" id="vision">
                                <h3>Notre vision</h3>
                                <p class="mb-20" v-html="apropos.vision"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gx-5">
                    <div class="col-lg-6">
                        <div ref="blockmission" class="about-content section mb-20" :class="{ visible: mission }" >
                            <div class="content-title style1" id="mission">
                                <h3>Notre mission est:</h3>
                                <p class="" v-html="apropos.mission"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-20"> <img src="../../assets/images/about/Transport-de-Cois-et-Marchandises.png" /></div>
                    </div>
                </div>

                <div class="row gx-5">
                    <div class="col-lg-12">
                        <div ref="blockmission" class="about-content section mb-20" :class="{ visible: mission }" >
                            <div class="content-title style1" id="mission">
                                <h3>Historique de La Poste du Bénin SA</h3>
                                <p v-html="apropos.historique"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="mb-20"> <img src="../../assets/images/about/Poste-AuxLettres.png" /></div>
                    </div>
                </div>
                <div class="row gx-5">
                    <div class="col-lg-12">
                        <div  ref="blockorganigramme" class="about-content section" :class="{ visible: organigramme }" >
                            <div class="content-title style1" id="organigramme">
                                <h2>Notre organigramme :</h2>
                                <p v-html="apropos.organigramme"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import ApiService from "@/services/ApiService";
import { defineComponent, nextTick, onMounted, onUnmounted, Ref, ref} from "vue";
import { Apropos } from "../../models/Apropos";
import { error } from "../../utils/utils";
import axios from "axios";

export default defineComponent({
    name: 'SmartBanking',
    components: {},
    setup() {
        const Aproposs = ref<Array<Apropos>>([]);   
        const vision = ref(false);
        const mission = ref(false);
        const historique = ref(false);
        const organigramme = ref(false);
        const valeur = ref(true);

        const blockvision = ref<HTMLElement | null>(null);
        const blockmission = ref<HTMLElement | null>(null);
        const blockhistorique = ref<HTMLElement | null>(null);
        const blockorganigramme = ref<HTMLElement | null>(null);
        const blockvaleur = ref<HTMLElement | null>(null);

        function scrollTo(view: Ref<HTMLElement | null>) { 
            view.value?.scrollIntoView({ behavior: 'smooth' }) 
        }
        const showSection = (section,view:Ref<HTMLElement | null> ) => {
            // if (section === 1) showVa()
            // if (section === 2) showV()
            // if (section === 3) showM()
            // if (section === 4) showH()
            // if (section === 5) showO()
            scrollTo(view);
        };

        const showV = () => {
            vision.value = true;
            mission.value = false;
            historique.value = false;
            organigramme.value = false;
            valeur.value = false;
        }

        const showVa = () => {
            valeur.value = true;
            vision.value = false;
            mission.value = false;
            historique.value = false;
            organigramme.value = false;
            
        }

        const showM = () => {
            mission.value = true;
            vision.value = false;
            historique.value = false;
            organigramme.value = false;
            valeur.value = false;
        }

        const showH = () => {
            historique.value = true;
            vision.value = false;
            mission.value = false;
            organigramme.value = false;
            valeur.value = false;
        }

        const showO = () => {
            organigramme.value = true;
            vision.value = false;
            mission.value = false;
            historique.value = false;
            valeur.value = false;
        }

        function getActifApropos() {
            return ApiService.get('/apropos/statut/1')
            .then(response => {
                Aproposs.value = response.data.data;
                return response.data.data;
            })
            .catch(({ response }) => {
                error(response.data.message)
            });
        }

        // Observateurs distincts pour chaque section
        let observer1, observer2, observer3, observer4, observer5;

        const createObserver = (sectionRef, visibilityRef) => {
            const observer = new IntersectionObserver(
                (entries) => {
                entries.forEach((entry) => {
                    visibilityRef.value = entry.isIntersecting;
                });
                },
                { threshold: 0.1 }
            );

            if (sectionRef.value) {
                observer.observe(sectionRef.value);
            }
            return observer;
        };
        

        onMounted(async () => {
            getActifApropos();
            // await nextTick();
            // observer1 = createObserver(blockvaleur, valeur);
            // observer2 = createObserver(blockhistorique, historique);
            // observer3 = createObserver(blockmission, mission);
            // observer4 = createObserver(blockorganigramme, organigramme);
            // observer5 = createObserver(blockvision, vision);
        });

        // onUnmounted(() => {
        //     if (observer1) observer1.disconnect();
        //     if (observer2) observer2.disconnect();
        //     if (observer3) observer3.disconnect();
        //     if (observer4) observer4.disconnect();
        //     if (observer5) observer5.disconnect();
        // });
     

        return {
            Aproposs,
            vision,
            valeur,
            mission,
            historique,
            organigramme,
            blockvision,
            blockvaleur,
            blockmission,
            blockhistorique,
            blockorganigramme,
            showSection,
            // showV,
            // showVa,
            // showM,
            // showH,
            // showO,
            getActifApropos
        };
    }
});
</script>

<style>
.section {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.section.visible {
  opacity: 1;
}
</style>